.ModalContainer{
    width: 100%;
    height: 100vh;
    background-color: rgba(128, 128, 128, 0.534);
    z-index: 900;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}