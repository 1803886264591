body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #7d7d7d;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
p {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  font-weight: 300;
  text-decoration: none;
  color: black;
}

a{ 
  font-family: 'Roboto', sans-serif;
  margin: 0;
  font-weight: 300;
  text-decoration: none;
}

h1{
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  text-decoration: none;
}


a{
  text-decoration: none;
}

h2{
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
}
h3{
  font-family: 'Roboto', sans-serif;
}

h4{
  font-family: 'Roboto', sans-serif;
}

li {
  color: black;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  text-decoration: none;
}
