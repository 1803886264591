.Logo{
    height: 85%;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.Logo h2{
    color: #ffffff;
    font-weight: 800;
}

.Logo img{
    height: 100%;
}

.Hide {
    display: none;
}

@media (min-width: 1200px) {
    .Logo {
       margin: initial;
       margin-right: auto;
       position: unset;
       top: unset;
       left: unset;
       transform: unset;
    }    
}