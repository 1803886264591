.NavigationItem{
    height: 100%;
    width: auto;
    align-items: center;
    display: block;
    margin: 8vw 5vw;
}

.NavigationItem a, .NavigationItem p {
    font-size: 4vw;
    color:#ffffff;
    text-decoration: none;
    width: 90vw;
    box-sizing: border-box;
    display: block;
}

.DropDownContainer {
    position: absolute;
    z-index: 999;
}

.DropDownContainer div {
    color: #ffffff;
    background-color:#49545f;
    padding: 1vw;
}

.DropDownContainer div:hover {
    background-color: #226ab2; 
    color: white;
}

@media only screen and (min-width: 768px) {
    .NavigationItem{
        margin: 4vw 5vw;
    }
    .NavigationItem a, .NavigationItem p {
        font-size: 4vw;
    }
}

@media only screen and (min-width: 1200px) {
    .NavigationItem {
        padding: 0 1vw;
        margin: 0;
       
    }
    
    .NavigationItem a, .NavigationItem p {
        font-size: 1vw;
        font-weight: 500;
        color: #ffffff;
        width: unset;
     
    }
    a.active {
        border-bottom: 2px solid #ffffff;
    }

    .DropDownContainer {
        position: absolute;
        z-index: 999;
    }
    
    .DropDownContainer div {
        color: #ffffff;
        background-color: #49545f;
        padding: 1vw;
    }
    
    .DropDownContainer div:hover {
        background-color: #ffffff; 
        color: #49545f;
    }
    
}
