.HomeInfo{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    overflow: hidden;

}

@media (min-width: 850px) {
    .HomeInfo{

    }

}