.NavigationItems {
    margin: 0 0;
    box-sizing: border-box;
    display: block;
    width: 100%;
    top: 56px;
    position: relative;
}

.BackButton{
    color: white;
    font-size: 2rem;
    width: 90%;
    margin: 0 auto;
}

@media only screen and (min-width: 768px) {

}

@media only screen and (min-width: 1200px) {
    .NavigationItems {
        flex-flow: row;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        top: unset;
        position: unset;
        padding: 0;
    }
}

@media (max-width: 1250px) {
    .NavigationItems {
        padding-inline-start:  10px;
    }
    
}