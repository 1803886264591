.Toolbar{
    width: 100%;
    background-color: #49545f;
}

.ToolbarHome{
    position: absolute;
    
}

.DesktopOnly {
    display: none;
}



.HeaderContainer{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    height: 80px;
    position: relative;
}

.Right{
    flex: 1;
}

.Hidden{
    display: none;
}

.Toolbar a {
    height: 100%;
}

@media (min-width: 1200px) {
    .DesktopOnly {
        display: block;
    }

    .Toolbar{
        display: flex;
        justify-content: center;
    }
    

    .HeaderContainer{
        height:10vh;
        max-width: 100em;
        box-sizing: border-box;      
        z-index: 90;   
        justify-content: center;
    }

    .ToolbarHome{
        width: 100%;
        background-color: white;      
        position: unset;
        display: flex;
        justify-content: center;
    }
}