.Addresses{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-around;
    flex-flow: row wrap;
}

@media (min-width: 850px) {
    .Addresses{
       max-width: 100em;
       margin: 0 auto;
    }

}
