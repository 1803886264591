.InfoContainer{
    margin: 1vw auto;
    width: 90%;
    height: auto;
    box-sizing: border-box;
    background-color: white;
}

.InfoContainer H2{
    text-align: center;
    padding: 5vw 0;
    color: #ffffff;
    letter-spacing: 0.7vw;
    font-weight: 500;
    background-color: #49545f;

}

.InfoContainer p{
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 5vw;
    width: 90%;
    margin: 1em auto;
    line-height: 1.4;
    color: black;
}

.LeftStart{
    transform: translateX(-100vw);
}

.RightStart{
    transform: translateX(100vw);
}

.Animate{
    transform: translateX(0);
    transition: transform 1.5s;
}

@keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
 }

 @media only screen and (min-width: 768px) {}

 @media only screen and (min-width: 1200px) {
    .InfoContainer{
        width: 32vw;
        margin: 2.5vw auto;
    } 

    .InfoContainer H2{
        padding: 1vw 0;
        letter-spacing: 0.3vw;
        font-size: 1.3vw;
    }
    .InfoContainer p{
        font-size: 1vw;
    }
}