.MenuIcon {
    width: 40px;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
    padding: 32px 0;
    box-sizing: border-box;
 																					
    position: static;
    z-index: 999;
    margin-left: 1em;
}

.MenuIcon div {
    width: 27px;
    height: 3px;
    transition: top, bottom, transform, background-color;
    transition-duration: 0.75s, 0.75s, 0.75s, 0.4s;
    position: relative;
}

.menuIconOpen{
    background-color: #ffffff;
}

.menuIconClosed{
    background-color: #ffffff;
}

.TransitionDelayClosed{
    transition-delay: 0.4s, 0.4s, 0s; 
}

.TransitionDelayOpen{
    transition-delay: 0s, 0s, 0.2s; 
}

.MenuTopClosed{
    top: 0;
    background-color: #ffffff;
}

.MenuBottomClosed{
    bottom: 0;
    background-color: #ffffff;
}

.TopShow{
    transform: rotate(45deg);   
    top: 4px;
    background-color: #ffffff;
}

.BottomShow{
    transform: rotate(-45deg);
    bottom: 4px; 
    background-color: #ffffff;
}


.menuIconOpenHome{
    background-color: #9f0b23;
}

.menuIconClosedHome{
    background-color: #ffffff;
}

.MenuTopClosedHome{
    top: 0;
    background-color: #ffffff;
}

.MenuBottomClosedHome{
    bottom: 0;
    background-color: #ffffff;
}

.TopShowHome{
    transform: rotate(45deg);   
    top: 4px;
    background-color: #9f0b23;
}

.BottomShowHome{
    transform: rotate(-45deg);
    bottom: 4px; 
    background-color: #9f0b23;
}



@media (min-width: 1200px) {
    .MenuIcon {
        display: none;
    }
}