.AdvertContainer{
    background-color: #49545f;;
    max-height: 50vh;
    width: 90%;
    position: relative;
    overflow: hidden;
}

.Cross{
    position: absolute;
    right: 5vw;
    top: 2vw;
    font-size: 7vw;
    font-weight: 500;
    cursor: pointer;
}

.AdvertTextContainer{
    width: 90%;
    margin: 10vw auto;
}

.AdvertTextContainer h1, p{
    color: #ffffff;
}

.AdvertTextContainer h1{
    font-weight: 400;
    margin-bottom: 1vw;
}

.AdvertTextContainer p{
    font-size: 1.3em;
    margin-bottom: 2vw;
    white-space: pre-line;
}  

.AdvertTextContainer a{
    text-decoration: none;
    color: #ffffff;
    font-weight: 500;
}   

.AdvertTextContainer h2{
    color: #fff;
    margin-bottom: 1vw;
    font-weight: 400;
}

.HandleClose{
    display: inline-block;
}

@media only screen and (min-width: 768px) {

}

@media only screen and (min-width: 1200px) {
    .AdvertContainer{
        max-width: 40vw;
        min-width: 35vw;
        max-height: 35vw;
    }

    .Cross{
        right: 1.5vw;
        top: 1vw;
        font-size: 2vw;
    }
    
    .AdvertTextContainer{
        margin: 2vw auto;
    }

    .AdvertTextContainer p{
        font-size: 1.3em;
        margin-bottom: 1vw;
    }  
}