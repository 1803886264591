.Footer{
    margin: 0;
    background-color: #2b2b2b;   

}

.FooterContent{
    padding: 1em 0;
}


@media (min-width: 850px) {
   .Footer{
    min-height: 30vh;
   }
}