.OfficeInfo{
    color: white;
    line-height: 1.5;
    width: 80%;
    margin: 1em auto;
    padding: 0 1em;
}

.OfficeInfo p{
    color: white;
    font-size: 1.5em;
}
.OfficeInfo h2{
    font-size: 1.5em;
}

.OfficeInfo h3{
    font-weight: 500;
}

@media (min-width: 850px) {
    .OfficeInfo{
        max-width: 30em;
        width: auto;
    }    
}


