.RegistredInfo{
    color: white;
    line-height: 1.5;
    width: 80%;
    margin: 1em auto;
    padding: 0 1em;
}

.RegistredInfo h2{
    margin: 0.8em 0;
}

.RegistredInfo p{
    color: white;
    font-size: 0.8rem;
}

.FooterImages{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    max-width: 15em;
}

@media (min-width: 850px) {
    .RegistredInfo{
        max-width: 30em;
        width: auto;
    }    
}