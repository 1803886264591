input, textarea{
    display: block;
    margin: 1em  auto;
    font-family: 'Roboto', sans-serif; 
    height: 2em;
    width: 80%;
    border-radius: 0;
    font-size: 1.3rem;
    font-weight: 300;
    border: none;
    text-indent: 0.2em;
    background-color: #efefef;
}

textarea{
    height: 14em;
}

h1{
    text-align: center;    
}

.FormContainer h2{
    text-align: center;
    color: white;
    font-weight: 400;
    letter-spacing: 0.1em;
    background-color: #49545f; 
    padding: 0.5em 0;
}

.FormContainer{
    width: 90%;
    padding-bottom: 1em;
    background-color:white;
    margin: 0 auto;
    margin-bottom: 1em;
}

.Contact{
    width: 100%;
     
}
.ContactContainer{
    width: 100%;
}

button{
    font-family: 'Roboto', sans-serif; 
    background-color: #49545f;
    border-radius: 2px;
    opacity: 0.95;
    margin: 1em auto;
    height: 3em;
    width: 8em;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f2f2f2;
    font-size: 1.5rem;
    cursor: pointer;
    font-weight: 500;
    border: none;
}
 
 button:hover{
    background-color: #226ab2;
 }

button:disabled{
    background-color: #2B2B2B;
    cursor: not-allowed;
    color: #f2f2f2;
}

.Contact p{ 
    font-size: 1.2rem;
}

.ConfirmationMessage{
    margin: 1em 0;
    
}

.ConfirmationMessage p{
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 1.1rem;
    line-height: 1.4;
    margin: 1em auto;
    width: 90%;
}

.ConfirmationMessage img{
    width: 10em;
    display: block;
    margin: 0 auto;
}

.InputError{
    border-bottom: 2px red solid;
}

.ErrorMessage{
    color: red;
    width: 90%;
    margin: 0 auto;
}

@media (min-width: 850px) {
        .Contact{
            max-width: 85em;
            margin: 0 auto;
            min-height: 70vh;
        }
        .ContactContainer{
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            align-items: baseline;
            margin: 1em 0;
        }
        .FormContainer{
            width: 40em;
            margin: 1em auto;
        }
        input, textarea{
            width: 90%;
            text-indent: 0.5em;
        }
}