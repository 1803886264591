.ContactDetails{
    margin: 1em auto;
    width: 90%;
    height: auto;
    box-sizing: border-box;
    background-color: #49545f;
    padding: 0.5em 0;
}

.ContactDetails a {
    color: black;
}

.ContactInfo{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: baseline;
    margin: 1em auto;
    width: 90%;
   
}

.Location{
    margin: 1em auto;
    width: 90%;
    padding-bottom: 1em;
}

.ContactDetails h2 {
    text-align: center;
    padding: 0.5em 0;
    color: #ffffff;;
    letter-spacing: 0.1em;
    font-weight: 400;
    background-color: #49545f;

}

.ContactInfo h3{
    margin: 0 0.2em 0 0;
    font-weight: 600;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 1.1rem;
    line-height: 1.4;
    color: #ffffff;;
}

.ContactInfo p, .Location p{
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 1.1rem;
    line-height: 1.4;
    color: #ffffff;
    font-weight: 600;
}


@media (min-width: 850px) {
    .ContactDetails{
        width: 40em;
        margin: 1em auto;
        padding: unset;
    }
}
