.MobileMenu {
    width: 100%;
    height: 0%;
    overflow: hidden;
    z-index: 50;
    background-color: #49545f;
    box-sizing: border-box;
    transition: height 0.5s linear;
    position: fixed;
    top: 0;
}

@media only screen and (min-width: 1200px) {
    .MobileMenu{
        display: none;
    }
}

.Open { 
    height: 100%;
    max-height: 100vh;
}

.Logo {
    height: 10px;
    margin-bottom: 32px;
}