.FooterImage img {
    max-width: 100%;
    height: 4em;
  }

  .FooterImage{
    flex-grow: 1;
    flex-shrink: 1;
}    


  @media (min-width: 850px) {
    .FooterImage{
        max-width: 5em;
    }    

}

