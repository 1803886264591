.MastHead{
      /* background-repeat:no-repeat;
      background-size:cover;
      background-position: center; */
      height: 60vh;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      flex-direction: column;
}

.MastHead img{
   height: 100%;
   width: 100%;
   object-fit: cover;
   animation: fadein 3s;
}

.ContactInfo{
   background-color: #9f0b23;
   border-radius: 2px;
   opacity: 0.95;
   margin: 1em auto;
   height: 4em;
   width: 11em;
   display: -webkit-box;
   display: -webkit-flex;
   display: -ms-flexbox;
   display: flex;
   align-items: center;
   justify-content: center;
}

.ContactInfo:hover{
   background-color: #226ab2;
}

.LogoContainer p {
   text-align: center;
   color: #f2f2f2;
   font-size: 1.5rem;
   font-weight: 500;
}

.LogoContainer{
   animation: fadein 1.8s;
}

@keyframes fadein {
   from {
       opacity:0;
   }
   to {
       opacity:1;
   }
}


@media only screen and (min-width: 768px) {
   .MastHead{
      height: 90vh;
   }

}

@media only screen and (min-width: 1200px) {
   .DesktopNav{
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      align-items: center;
      height: 10vh;
      background-color:#fff ;
   }

   .MastHead{
      flex-direction: row;
      justify-content: space-evenly;

   }
    
}
