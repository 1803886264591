.Image{
    height: auto;
    overflow: hidden;
}
.Image img {
    max-width: 100%;
    height: auto;
    opacity: 0.8;
}

.Image:hover{
    background-color: #49545f;
    
}

@media (min-width: 850px) {
    .Image{
        height: 18vw;
        overflow: hidden;
        /* margin-bottom: 2em; */
    }
}